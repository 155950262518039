"use client";
import { Section } from "@cgc/ui/layout";
import { Heading } from "@cgc/ui/typography";
import Script from "next/script";
import React, { useEffect, useState } from "react";

const ReviewIOWidget: React.FC<{ id: string; title?: string }> = ({
  id,
  title,
}) => {
  const [scriptReady, setScriptReady] = useState(false);

  useEffect(() => {
    if (scriptReady) {
      window.richSnippetReviewsWidgets("compound-widget", {
        store: "craft-clubs",
        primaryClr: "#343579",
        neutralClr: "#343579",
        reviewTextClr: "#343579",
        widgetName: "compound",
        layout: "fullWidth",
        numReviews: 3,
        contentMode: "company;third-party",
        compound: "google local",
        css:
          ".CompoundWidget .compound-reviews-container .review-container .review-content .rating-stars {font-size: 18px; color: #f4da40;}" +
          ".CompoundWidget .compound-reviews-container .review-container .review-content .review-comments {font-size: 16px;}",
      });
    }
  }, [scriptReady]);

  return (
    <Section id={id} className="mb-0 xl:px-[240px]">
      <Script
        id="rich-snippet-reviews-widgets"
        src="https://widget.reviews.co.uk/rich-snippet-reviews-widgets/dist.js"
        onLoad={() => setScriptReady(true)}
      />
      <div className="text-cgc-blue w-full text-center">
        <Heading size="h2">{title}</Heading>
      </div>
      <div id="compound-widget"></div>
    </Section>
  );
};

export default ReviewIOWidget;
