"use client";
import { HelpText, Label, TextInputProps } from "@cgc/ui/Form";
import { Paragraph } from "@cgc/ui/typography";
import { clsx } from "@cgc/ui/utils/clsx";
import { useField } from "formik";
import React from "react";

type DatePickerProps = TextInputProps & {
  name: string;
  label?: string;
  helpText?: string;
  className?: string;
  wrapperClassName?: string;
};

const DatePicker: React.FC<DatePickerProps> = ({
  name,
  label,
  helpText,
  className,
  wrapperClassName,
  ...props
}) => {
  const [field, meta] = useField(name);
  const fieldId = `id_${field.name}`;
  const hasError = meta.touched && meta.error;
  const color = hasError ? "failure" : undefined;

  return (
    <div className={clsx("space-y-0.5", wrapperClassName)}>
      {label && (
        <Paragraph>
          <Label htmlFor={fieldId} color={color}>
            {label}
          </Label>
        </Paragraph>
      )}

      {/* link to calendar icon of font awesome */}
      <link
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <input
        name="terminationDate"
        type="date"
        id={fieldId}
        className={className}
        min={new Date().toISOString().split("T")[0]}
        {...props}
        {...field}
      />
      {helpText && !hasError && <HelpText>{helpText}</HelpText>}
      {hasError && <HelpText error>{meta.error}</HelpText>}
    </div>
  );
};

export default DatePicker;
