"use client";

import { CheckIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { useField } from "formik";
import React, { ChangeEvent, useState } from "react";

type Props = {
  name: string;
  label?: string;
  className?: string;
  handleCheckboxChange: (_event: ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: React.FC<Props> = ({
  name,
  label,
  className,
  handleCheckboxChange,
}) => {
  const [field] = useField(name);
  const fieldId = `id_${field.name}`;
  const [isChecked, setIsChecked] = useState(true);

  const handleChange = (_event: ChangeEvent<HTMLInputElement>) => {
    _event.preventDefault();
    setIsChecked(!isChecked);
    handleCheckboxChange(_event);
  };

  return (
    <div className="container relative mb-3 flex cursor-pointer items-center text-base">
      <label className={clsx("select-none", className)}>
        <input
          type="checkbox"
          className="absolute opacity-0"
          checked={isChecked}
          onChange={handleChange}
          value={label}
          id={fieldId}
        />
        <div className="flex flex-row">
          <div className="h-5 w-5 min-w-5 justify-center rounded-sm border border-black bg-white align-middle">
            {isChecked ? <CheckIcon className="h-4 w-4" /> : ""}
          </div>

          <span className="pl-6 text-left">{label}</span>
        </div>
      </label>
    </div>
  );
};

export default Checkbox;
