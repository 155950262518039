/**
 * Type for the form to be submitted
 */
"use client";

import { getItem } from "@/utils/cache";

export interface JoinUsForm {
  email: string;
  marketing: boolean;
}

const products = [
  "gotm",
  "gotm_taster",
  "gotm_taster_2",
  "gotm_taster_3",
  "gotm_taster_4",
  "rotm",
  "rotm_taster",
  "rotm_taster_2",
];

type Params = {
  email: string;
  product?: string;
  discountCode?: string;
};

export function submitJoinUsForm(params: Params) {
  const code = handleDiscount(params.discountCode);
  const product = handleProduct(params.product, code);
  const url = getUrl(product, code, params.email);

  return url;
}

function getUrl(product: string, code: string, email: string) {
  const urlBase = `https://club.craftginclub.co.uk/pages/checkout-address`;
  const discountParam = code.length ? `discount_code=${code}` : null;
  const productParam = product.length ? `product=${product}` : null;
  const emailParam = email.length ? `customer_email_address=${email}` : null;

  if (!(discountParam || productParam || emailParam)) {
    return urlBase;
  } else {
    const paramArray = [discountParam, productParam, emailParam];
    const filteredArray = paramArray.filter((value) => Boolean(value));
    const subdirectory = filteredArray.join("&");
    return `${urlBase}?${subdirectory}`;
  }
}

function handleDiscount(paramCode?: string) {
  const cacheCode = getItem("discount_code");
  const code = paramCode || (cacheCode ? cacheCode : "").toUpperCase();

  return code;
}

function handleProduct(paramProduct: string = null, code: string = null) {
  const isTasterBox2 =
    code.startsWith("SIP-2-") ||
    ["SIP-TASTER-FB2", "SIP-TASTER-SOV2"].includes(code);
  const isTasterBox3 = code.startsWith("SIP-3-");
  const isTasterBox4 = code.startsWith("GIN-TASTER-3-");
  const isTasterBox =
    code.startsWith("MM-SIP-") || (!isTasterBox2 && code.startsWith("SIP-"));

  const isGinDiscount = code === "30OFF-HP";

  const isRumTasterBox2 = code.startsWith("RUM-TASTER-2-");
  const isRumTasterBox = !isRumTasterBox2 && code.startsWith("RUM-TASTER-");

  function classifyProduct(): string | null {
    let product;
    if (isGinDiscount) product = "gotm";
    else if (isTasterBox) product = "gotm_taster";
    else if (isTasterBox2) product = "gotm_taster_2";
    else if (isTasterBox3) product = "gotm_taster_3";
    else if (isTasterBox4) product = "gotm_taster_4";
    else if (isRumTasterBox) product = "rotm_taster";
    else if (isRumTasterBox2) product = "rotm_taster_2";
    else if (
      !(isTasterBox || isTasterBox2 || isTasterBox3 || isTasterBox4) &&
      code.startsWith("gotm_taster")
    )
      product = "gotm";

    if (!products.includes(product)) product = "gotm";

    return product || null;
  }

  const cacheProduct = getItem("product");
  const product =
    paramProduct || cacheProduct?.toLowerCase() || classifyProduct();

  return product;
}
