"use client";

import { usePathname, useSearchParams } from "next/navigation";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useCookies } from "react-cookie";
import {
  DiscountSet,
  Replacement,
  extractDiscountAndOffer,
} from "./extractDiscountAndOffer";

export type OfferContextType = {
  basePrice: string;
  offerPrice: string;
  replacements: Replacement[];
  discounts: DiscountSet[];
  discountForUrl: (url: string) => DiscountSet;
};

const OfferContext = createContext<OfferContextType>({
  basePrice: "",
  offerPrice: "",
  replacements: [],
  discounts: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  discountForUrl: (_url: string) => {
    return { discount: "", offerValue: "", offerPrice: "", replacements: [] };
  },
});

export const useOffer = () => useContext(OfferContext);

export type PrismicOfferContext = {
  page;
};

export const OfferProvider = ({
  children,
  prismicContext,
}: PropsWithChildren<{
  prismicContext: PrismicOfferContext;
}>) => {
  const [, setReqTokenCookie] = useCookies(["sovReqToken", "sovReqPath"]);
  const pathName = usePathname();
  const query = useSearchParams();

  const discounts = extractDiscountAndOffer(
    prismicContext.page,
    pathName,
    query,
  );

  useEffect(() => {
    const token = query.get("sovReqToken");

    if (token) {
      setReqTokenCookie("sovReqToken", token, {
        domain: ".craftginclub.co.uk",
      });

      setReqTokenCookie("sovReqPath", pathName, {
        domain: ".craftginclub.co.uk",
      });
    }
  }, [query, discounts, setReqTokenCookie]);

  // Extract offer value from the first discount if it exists, else use the base offer string
  const offerPrice =
    discounts[0]?.offerPrice || prismicContext.page.data.base_offer_string;
  const replacements = discounts[0]?.replacements || [];

  const discountForUrl = useCallback(
    (url: string) => {
      // Find the first discount where the discount url matches the given url
      const matchedDiscount = discounts.find(
        (discount) => discount.url === url,
      );

      if (matchedDiscount) {
        return matchedDiscount;
      }

      // If no discount was found, return the first discount with no URL set
      return (
        discounts.find((discount) => !discount.url) || {
          discount: "",
          offerPrice: "",
          replacements: [],
        }
      );
    },
    [discounts],
  );

  return (
    <OfferContext.Provider
      value={{
        basePrice: prismicContext.page.data.base_offer_string,
        discounts,
        offerPrice,
        replacements,
        discountForUrl,
      }}
    >
      {children}
    </OfferContext.Provider>
  );
};

export const __UnimplementedOfferProvider = ({
  children,
}: PropsWithChildren) => {
  return (
    <OfferContext.Provider
      value={{
        basePrice: "",
        discounts: [],
        offerPrice: "",
        replacements: [],
        discountForUrl: () => {
          return null;
        },
      }}
    >
      {children}
    </OfferContext.Provider>
  );
};
