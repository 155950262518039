import { kv } from "@vercel/kv";
import spark from "spark-md5";
import { v4 as uuidv4 } from "uuid";

const DEFAULT_EXPIRE_SECONDS = 60 * 15; // 15 minutes

export function encode(value: any, expire: number): string {
  return JSON.stringify({
    value: value,
    expire: Date.now() + expire * 1000,
  });
}

export function decode(rawValue: string | Record<string, any>) {
  const { expire, value } =
    typeof rawValue === "string" ? JSON.parse(rawValue) : rawValue;

  return { value, expired: expire < Date.now() };
}

var latestPrefix: string | undefined;

export async function getPrefix(): Promise<string> {
  if (latestPrefix) {
    // console.log("latestPrefix", latestPrefix);
    return latestPrefix;
  }
  const prefixKey = "cgc:cache:namespace";
  let prefix = (await kv.get(prefixKey)) as string;
  if (prefix) {
    // console.log("Fetched prefix from cache");
    latestPrefix = prefix;

    return prefix;
  }
  // console.log("Generate new prefix");
  prefix = uuidv4();
  await kv.set(prefixKey, prefix, { ex: 60 * 60 * 24 * 365 });
  latestPrefix = prefix;

  return prefix;
}

export async function makeKey(key: string) {
  const prefix = await getPrefix();

  return `${prefix}:${spark.hash(key)}`;
}

export async function set(
  key: string,
  value: any,
  expire = DEFAULT_EXPIRE_SECONDS,
) {
  const cacheKey = await makeKey(key);
  try {
    await kv.set(cacheKey, encode(value, expire), {
      ex: 60 * 60 * 24, // SET CACHE FOR 24HOURS BY DEFAULT
    });
  } catch (error) {
    /* empty */
  }
}

export async function get(key: string) {
  const cacheKey = await makeKey(key);
  try {
    const rawValue = await kv.get(cacheKey);
    if (rawValue) {
      return decode(rawValue);
    }
  } catch (err) {
    /* empty */
  }
}

// eslint-disable-next-line no-unused-vars
type CacheKey = (args: any) => string;

export function cached(key: CacheKey, expire: number = DEFAULT_EXPIRE_SECONDS) {
  // eslint-disable-next-line no-unused-vars
  return function <FT extends (...args: any) => any>(func: FT) {
    return async function (args?: any): Promise<ReturnType<FT>> {
      const cacheKey = key(args);
      const result = await get(cacheKey);
      if (!result) {
        const funcResult = await func(args);
        await set(cacheKey, funcResult, expire);

        return funcResult;
      }
      if (result.expired) {
        (async function () {
          const funcResult = await func(args);
          await set(cacheKey, funcResult, expire);
        })().then();
      }

      return result.value;
    };
  };
}

export function getItem(key: string) {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(key);
  }

  return null;
}
