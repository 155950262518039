export const fallbackLanguage = "en-us";
export const languages = [
  fallbackLanguage,
  "de-de",
  "de-at",
  "nl-nl",
  "nl-be",
  "fr-be",
  "fr-fr",
  "it-it",
  "es-es",
];
export const defaultNS = "translation";
export const cookieName = "i18next";

export function getOptions(
  lng = fallbackLanguage,
  ns: string | string[] = defaultNS,
) {
  return {
    supportedLanguages: languages,
    fallbackLanguage,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  };
}
